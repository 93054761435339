/**
 * Main App it will not takeover whole apps but it just used for several places to 
 * minimized work 
 * all over the project and accessble from any cakephp Plugin. 
 * @author Sarwar Hossain (Instalogic Inc.)
 * @class InstaApp
 * 
 */
var InstaApp = angular.module("InstaApp", [
    "ui.router",
    "ui.bootstrap",
    "ngSanitize",
    "angular-uuid"
]);

/**
 * Default run item after initialization 
 * 
 */
InstaApp.run(["$rootScope", "settings", function ($rootScope, settings) {

        $rootScope.$settings = settings;


    }]);

InstaApp.config([function () {

    }]);

/**
 * Settings Factory all global path
 */
InstaApp.factory('settings', ['$rootScope', function ($rootScope) {
        var settings = {
            baseURL: BASE_URL,
            assetsPath: BASE_URL + 'assets/',
            jsPath: BASE_URL + 'js/',
            cssPath: BASE_URL + 'css/',
            imgPath: BASE_URL + 'img/'

        };
        $rootScope.settings = settings;
        return settings;
    }]);




/**
 * Application Main Controller 
 */
InstaApp.controller('AppController', ['$scope', '$rootScope', '$compile', '$http', function ($scope, $rootScope, $compile, $http) {
        // set compile to use on jquery view/html
        $scope.compile = $compile;
        //Used jquery after laod to set default value 
        $scope.setSeledtDefaultValue = function (id, val) {
            $(function () {
                angular.element(id).val(val);
            });


        };


        /**
         * client staff list
         */
        if ($("#staff-id").length && $("#client-id").length) {
            $http({
                url: BASE_URL + "settings/ng-request/getClientStaffList/",
                method: "GET"
            })
                    .success(function (data, status, headers, config) {
                        $scope.lookUpUserList = data;
                    }).error(function (data, status, headers, config) {
                return status;

            });
        }

        /**
         * Generate the user list based on permission 
         * @param Int clientId Find staff based on clientId
         * @return Array - List of key pair 
         */
        $scope.getClientStaffList = function (clientId) {
            clientId = (typeof clientId !== 'undefined') ? clientId : null;
            return $http({
                url: BASE_URL + "settings/ng-request/getClientStaffList/" + clientId,
                method: "GET"
            })
                    .success(function (data, status, headers, config) {
                        $scope.lookUpUserList = data;
                    }).error(function (data, status, headers, config) {
                return status;

            });
        };

        /*
         * parse date str to timestamp value
         */
        $scope.parseDate = function (date) {
            return Date.parse(date);

        };

        /*
         * parse JSON
         */
        $scope.parseJson = function (str) {
            return JSON.parse(str);
        };

        $scope.afterFinishRepeat = function (id, itemId) {
            $("#" + itemId).val(id).trigger("change");

        };

    }]).directive('onFinishRender', ["$timeout", function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                if (scope.$last === true) {
                    $timeout(function () {
                        var ids = scope.$eval(attr.onFinishRender);
                        var itemId = attr.afterId;
                        if (ids != "") {
                            scope.afterFinishRepeat(ids, itemId);
                        }

                    });
                }
            }
        };
    }]);



